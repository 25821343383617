import React, { useState } from 'react';

function Services() {
  const [activeIndex, setActiveIndex] = useState(null); // Track the active index

  const toggleShow = (index) => {
    // If the clicked item is already active, close it by setting activeIndex to null
    setActiveIndex(activeIndex === index ? null : index);
  };

  const careerdata = [
    {
      title: 'Development',
      subtitle: 'Web Developments',
      img: '/light/assets/imgs/works/1/4.webp',
      description: `At Aashway, we believe in building high-performance, scalable, and efficient web applications. The Web Development internship program is designed to equip candidates with in-depth knowledge and practical experience in modern web technologies. `,
      subpoints : [
        "Work with Angular, React.js, JavaScript, TypeScript, HTML, and CSS to create dynamic and responsive user interfaces.", 
        "Learn how to integrate APIs, manage databases, and build scalable web applications using Node.js, PHP, and Firebase.", 
        "Work with MySQL, MongoDB, and Firebase for data storage and cloud solutions.", 
        "Collaborate using Git, GitHub, and deploy projects on AWS, Firebase, and other cloud platforms.",
        "Gain experience in optimizing applications, debugging issues, and enhancing user experience."
      ],
      contact: 'This six-month program ensures that interns develop a deep understanding of real-world web development, enabling them to handle industry-level projects effectively.'
    },
    {
      title: 'UI-UX Design',
      subtitle: 'UI / UX Designing',
      img: '/light/assets/imgs/works/1/3.webp',
      description: `Our UI/UX Design internship is tailored for individuals who are passionate about creating visually appealing, user-friendly, and interactive digital experiences.`,
      subpoints: [
        "Conduct market and user research to understand customer behavior and preferences.",
        "Develop interactive wireframes and prototypes using Figma, Adobe XD, and Sketch.",
        "Craft intuitive interfaces with well-structured layouts, typography, and animations.",
        "Learn to bridge the gap between design and development, ensuring a seamless transition from concept to execution."
      ],
      contact: 'Throughout the six-month training period, interns gain a deep understanding of UI/UX principles, industry-standard tools, and real-world project implementation'
    },
    {
      title: 'Marketing',
      subtitle: 'Digital Marketing',
      img: '/light/assets/imgs/works/1/2.webp',
      description: `Our Digital Marketing internship provides candidates with a strong foundation in online marketing strategies, branding, and audience engagement.`,
      subpoints: [
        "Develop and execute marketing campaigns across social media, email, and other digital channels.",
        "Analyze data and metrics to identify trends, insights, and optimize marketing strategies.",
        "Create engaging content, graphics, and videos for social media and other marketing materials.",
        "Learn to use marketing tools such as Google Analytics, AdWords, and SEO to drive traffic and conversions."
      ],
      contact: 'This six-month program ensures that interns gain practical experience in digital marketing, content creation, and campaign management.'
    },
    {
      title: 'Graphic Design',
      subtitle: 'Product Design',
      img: '/light/assets/imgs/works/1/1.webp',
      description: `Our Graphic Design internship offers individuals the opportunity to enhance their design skills while working on branding, marketing, and digital media projects.`,
      subpoints: [
        "Work on logo design, brand development, and corporate branding strategies.",
        "Design banners, posters, and social media creatives for online marketing.",
        "Develop brochures, flyers, advertisements, and promotional material.",
        "Learn Adobe After Effects, Premiere Pro, and Canva to create engaging video content.",
        "Create custom illustrations and vector-based designs using Adobe Illustrator and Procreate."
      ],
      contact: 'This six-month structured internship helps interns develop a diverse portfolio while gaining real-world experience in branding and digital marketing projects.'

    },
  ];

  return (
    <section className="portfolio-tab crev section-padding">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Career Opportunities</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2 className="fw-600 text-u ls1">
              Join Our <span className="fw-200">Team</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="glry-img">
              {careerdata.map((val, index) => (
                <div
                  key={index}
                  id={`tab-${index + 1}`}
                  className={`bg-img tab-img ${activeIndex === index ? 'current' : ''}`}
                  data-background={val.img}
                ></div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 content">
            {careerdata.map((val, index) => (
              <div className="border-bottom mb-30" key={index}>
                <div className="cluom" data-tab={`tab-${index + 1}`}>
                  <div className="info">
                    <h6 className="sub-title opacity-7">{val.title}</h6>
                    <h4>{val.subtitle}</h4>
                  </div>
                  <div className="more text-u ls1 fz-12 main-menu">
                    <a
                      href="/"
                      className='transition-transform duration-300'
                      onClick={(e) => {
                        e.preventDefault(); 
                        toggleShow(index); 
                      }}
                    >
                      View Details
                    </a>
                  </div>
                </div>
                <div className={`description-transition ${activeIndex === index ? 'active' : ''}`}>
                  <p className='pb-10 px-3 px-sm-0'>{val.description}</p>
                  <p className='font-bold px-3 px-sm-0'>Requirements</p>
                  {val.subpoints && (
                    <ul className="px-3 px-sm-0">
                      {val.subpoints.map((point, i) => (
                        <li key={i} className='!text-[#777] font-bold'><p>{point}</p></li>
                      ))}
                    </ul>
                  )}
                  <p className='pb-10 px-3 px-sm-0'>{val.contact}</p>
                  <a href='mailto:contact@aashway.in' className='px-3 px-sm-0'>
                    <button type="submit" className="butn butn-bord radius-30 mb-10 px-4 py-2">
                      <span className="text">Send Resume</span>
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;