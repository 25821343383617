import React from 'react';

function SecNavbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container">
          <a className="logo icon-img-100" href="/">
            <img src="/light/assets/imgs/logo.webp" alt="logo" />
          </a>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="/"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="rolling-text">Home</span>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="/services"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="rolling-text">Services</span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/services/web-development">
                     Web Development 
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/services/social-media-marketing">
                     Social Media Marketing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/services/graphic-designing">
                     Graphic Designing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/services/ui-ux-designing">
                    UI / UX Designing
                    </a>
                  </li>
                </ul>
                {/* <div className="dropdown-menu mega-menu">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="clumn mb-80">
                          <div className="title">
                            <h6 className="sub-title ls1 pb-20 bord-thin-bottom">
                              Portfolio Type
                            </h6>
                          </div>
                          <div className="links row">
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/portfolio-standard"
                              >
                                Standerd
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/portfolio-gallery"
                              >
                                Gallery
                              </a>
                              <a
                                className="dropdown-item"
                                href="/ight/portfolio-metro"
                              >
                                Metro
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/masonry"
                              >
                                Masonry
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/portfolio-caption-curso"
                              >
                                Caption Cursor
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/outline"
                              >
                                Outline
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/portfolio-parallax"
                              >
                                Parallax
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/portfolio-sticky"
                              >
                                Sticky
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="clumn">
                          <div className="title">
                            <h6 className="sub-title ls1 pb-20 bord-thin-bottom">
                              Showcases
                            </h6>
                          </div>
                          <div className="links row">
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/showcase-fullscreen"
                              >
                                Parallax Slider
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/showcase-carousel"
                              >
                                Showcase Carousel
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/showcase-half-slider"
                              >
                                Creative Slider
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/showcase-interactive-full"
                              >
                                Interactive Full
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/showcase-interactive-center"
                              >
                                Interactive Center
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/showcase-interactive-vertical"
                              >
                                Interactive Vertical
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="clumn">
                          <div className="title">
                            <h6 className="sub-title ls1 pb-20 bord-thin-bottom">
                              Portfolio Single
                            </h6>
                          </div>
                          <div className="links row">
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/project1"
                              >
                                project1
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/project2"
                              >
                                project2
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/project3"
                              >
                                project3
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/project4"
                              >
                                project4
                              </a>
                            </div>
                            <div className="col-lg-4">
                              <a
                                className="dropdown-item"
                                href="/light/project5"
                              >
                                project5
                              </a>
                              <a
                                className="dropdown-item"
                                href="/light/project6"
                              >
                                project6
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  data-toggle="dropdown"
                  href="/about-us"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="rolling-text">About us</span>
                </a>
                {/* <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <a href="#0">
                      About Us <i className="fas fa-angle-right icon-arrow"></i>
                    </a>
                    <ul className="dropdown-side">
                      <li>
                        <a className="dropdown-item" href="/light/page-about">
                          About Us 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/light/page-about2">
                          About Us 2
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/light/page-about3">
                          About Us 3
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown-item">
                    <a href="#0">
                      Services <i className="fas fa-angle-right icon-arrow"></i>
                    </a>
                    <ul className="dropdown-side">
                      <li>
                        <a
                          className="dropdown-item"
                          href="/light/page-services"
                        >
                          Services 1
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/light/page-services2"
                        >
                          Services 2
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/light/page-services-details"
                        >
                          Services Details
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown-item">
                    <a href="#0">
                      Contact Us{' '}
                      <i className="fas fa-angle-right icon-arrow"></i>
                    </a>
                    <ul className="dropdown-side">
                      <li>
                        <a className="dropdown-item" href="/light/page-contact">
                          Contact Us 1
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/light/page-contact2"
                        >
                          Contact Us 2
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/light/page-contact3"
                        >
                          Contact Us 3
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/light/page-team">
                      Our Team
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/light/page-team-single">
                      Team Details
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/light/page-FAQS">
                      FAQS
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/light/page-error-404">
                      Error 404
                    </a>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  data-toggle="dropdown"
                  href="/career"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="rolling-text">Career</span>
                </a>
                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="/light/blog-classic">
                    Blog Standerd
                  </a>
                  <a className="dropdown-item" href="/light/blog-list">
                    Blog List
                  </a>
                  <a className="dropdown-item" href="/light/blog-list2">
                    Blog List 2
                  </a>
                  <a className="dropdown-item" href="/light/blog-details">
                    Blog Details
                  </a>
                </div> */}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  <span className="rolling-text">Contact Us</span>
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="search-form">
            <div className="form-group">
              <input type="text" name="search" placeholder="Search" />
              <button>
                <span className="pe-7s-search"></span>
              </button>
            </div>
            <div className="search-icon">
              <span className="pe-7s-search open-search"></span>
              <span className="pe-7s-close close-search"></span>
            </div>
          </div> */}
          <div className="topnav">
            <div className="menu-icon cursor-pointer">
              <span className="icon ti-align-right"></span>
            </div>
          </div>
        </div>
      </nav>

      <div className="hamenu">
        <div className="logo icon-img-100">
          <img src="/light/assets/imgs/logo-light.png" alt="" />
        </div>
        <div className="close-menu cursor-pointer ti-close"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="menu-text">
                <div className="text">
                  <h2>Menu</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="menu-links">
                <ul className="main-menu rest">
                  <li>
                  <div className="o-hidden">
                      <a href="/" className="link">
                        <span className="fill-text" data-text="Home">
                          Home
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <div className="link cursor-pointer dmenu">
                        <span className="fill-text" data-text="Services">
                          Services
                        </span>{' '}
                        <i></i>
                      </div>
                    </div>
                    <div className="sub-menu no-bord">
                      <ul>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/web-development" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Web Development">
                                Web Development
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/social-media-marketing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Social Media Marketing">
                                Social Media Marketing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/graphic-designing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Graphic Designing">
                                Graphic Designing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/ui-ux-designing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="UI - UX Designing">
                                UI - UX Designing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="/about-us" className="link">
                        <span className="fill-text" data-text="About Us">
                          About Us
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="career" className="link">
                        <span className="fill-text" data-text="Career">
                          Career
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="/contact" className="link">
                        <span className="fill-text" data-text="Contact Us">
                          Contact Us
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="cont-info">
                <div className="item mb-50">
                  <h6 className="sub-title mb-15 opacity-7">Address</h6>
                  <h5>
                  Thane, <br /> Maharashtra, India - 400603
                  </h5>
                </div>
                <div className="item mb-50">
                  <h6 className="sub-title mb-15 opacity-7">Social Media</h6>
                  <ul className="rest social-text">
                    <li className="mb-10">
                      <a href="https://www.linkedin.com/company/aashway/" className="hover-this">
                        <span className="hover-anim">LinkedIn</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/beaashway/" className="hover-this">
                        <span className="hover-anim">Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item mb-40">
                  <h6 className="sub-title mb-15 opacity-7">Contact Us</h6>
                  <h5>
                    <a href="mailto:contact@aashway.in">contact@aashway.in</a>
                  </h5>
                  <h5 className="underline mt-10">
                    <a href="tel:+91 86524 16407">+91 86524 16407</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecNavbar;
